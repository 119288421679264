import React from 'react';
import { Link } from 'gatsby';
import styles from './index.module.css';

const Button = props => {
  let button;
  const { type, text, className, route } = props;
  if (type === 'submit') {
    button = (
      <button type="submit" className={className}>
        {text}
      </button>
    );
  } else {
    button = (
      <Link to={route}>
        <button type="button" className={styles.button}>
          {text}
        </button>
      </Link>
    );
  }

  return <div>{button}</div>;
};

export default Button;
